import React, { useState, useEffect } from 'react';

function Timer() {

    const calculateTimeLeft = () => {
        const difference = +new Date('2024-02-10') - +new Date();
        let timeLeft = {};
    
        if (difference > 0) {
          timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60),
          };
        }
    
        return timeLeft;
      };
    
      const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    
      useEffect(() => {
        const timer = setTimeout(() => {
          setTimeLeft(calculateTimeLeft());
        }, 1000);
    
        // Clear timeout if the component is unmounted
        return () => clearTimeout(timer);
      });

  return (
    <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
    <div className="countdown" style={{boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)', width: 400, height: 100, padding: 0, borderRadius: 30, marginTop: -20, zIndex: 200, position: 'absolute', alignSelf: 'center'}}>
    {Object.keys(timeLeft).length > 0 ? (
      Object.keys(timeLeft).map((interval, index) => (
        <div key={index} className="countdown-item">
          <div className="countdown-time" style={{fontFamily: 'PTSans'}}>{timeLeft[interval]}</div>
          <div className="countdown-label" style={{fontFamily: 'PTSans'}}>{interval}</div>
        </div>
      ))
    ) : (
      <span>Time's up!</span>
    )}
  </div>
  </div>
  )
}

export default Timer