import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/auth';

const firebaseConfig = {
    apiKey: "AIzaSyDEYT5O6_93qad1hd7krN3KgDNWTMr0W8w",
    authDomain: "gracevillecc-41b81.firebaseapp.com",
    projectId: "gracevillecc-41b81",
    storageBucket: "gracevillecc-41b81.appspot.com",
    messagingSenderId: "136990052852",
    appId: "1:136990052852:web:94325804d62428025c29a1"
};

firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
export const auth = firebase.auth()
export const storage = firebase.storage()
export const currentTime = firebase.firestore.Timestamp