import React from 'react'
import NavBar from '../components/NavBar';
import Sterling from '../assets/images/sterling.png'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';

function ProofOfPayment() {
    return (
        <div style={{ width: '100%', backgroundColor: '#21416D', height: 1500 }}>
            <NavBar />
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 55, fontSize: 20, fontFamily: 'RobotoBold', textAlign: 'center', color: '#FA0468', }}>
                <div style={{ width: '80%', fontSize: 20, fontFamily: 'RobotoBold', textAlign: 'center', color: '#FA0468', }}> Kindly pay to the account number below and submit prove of payment to the phone number or email below</div>
            </div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 15 }}>
                <img src={Sterling} style={{ width: 150 }} />
            </div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 5, fontSize: 40, fontFamily: 'RobotoBold', textAlign: 'center', color: '#fff' }}>
                ACCOUNT NUMBER: 0009539638
            </div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 5, fontSize: 30, fontFamily: 'RobotoBold', textAlign: 'center', alignItems: 'center' }}>
                <div><WhatsAppIcon style={{ color: '#fff', fontSize: 40 }} /></div>
                <div style={{ marginLeft: 10, color: '#fff' }}>08155145432</div>
            </div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 5, fontSize: 30, fontFamily: 'RobotoBold', textAlign: 'center', alignItems: 'center' }}>
                <div><EmailIcon style={{ color: '#fff', fontSize: 40 }} /></div>
                <div style={{ marginLeft: 10, color: '#fff' }}> gracevillexposure@gmail.com</div>
            </div>
        </div>
    )
}

export default ProofOfPayment