import React from 'react'
import Banner from '../components/Banner'
import Timer from '../components/Timer'
import TicketSales from '../components/TicketSales'
import Sliders from '../components/Sliders'
import Event from '../components/Event'

function HomeScreen() {
    return (
        <div style={{width: '100%'}}>
            <Banner />
            <Timer />
            <Event />
            <Sliders />
        </div>
    )
}

export default HomeScreen