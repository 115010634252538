import React, {useState, useEffect} from 'react'
import Ban1 from '../assets/images/ban1.jpg'
import Ban2 from '../assets/images/ban2.jpg'
import Ban3 from '../assets/images/ban3.jpg'
import Ban4 from '../assets/images/ban4.jpg'

function Sliders() {

    const contents = [
        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <img src={Ban1} style={{ width: '100%', position: 'absolute', zIndex: -100, objectFit: 'contain' }} />
        </div>,

        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <img src={Ban2} style={{ width: '100%', position: 'absolute', zIndex: -100, objectFit: 'cover' }} />
        </div>,

        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <img src={Ban3} style={{ width: '100%', position: 'absolute', zIndex: -100, objectFit: 'cover' }} />
        </div>,

        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <img src={Ban4} style={{ width: '100%', position: 'absolute', zIndex: -100, objectFit: 'cover' }} />
        </div>,
        // ... add more content elements here
    ];

    const [current, setCurrent] = useState(0);

    // Function to update the current content index
    const nextSlide = () => {
        setCurrent(current === contents.length - 1 ? 0 : current + 1);
    };

    // Automatically move to the next slide after a certain period of time
    useEffect(() => {
        const timer = setTimeout(() => {
            nextSlide();
        }, 5000); // Change content every 3 seconds

        // Cleanup the interval on component unmount
        return () => clearTimeout(timer);
    }, [current, contents]);

    // Ensure there are contents to display
    if (!Array.isArray(contents) || contents.length <= 0) {
        return null;
    }

    return (
        <div style={{ width: '100%', height: 'auto'}}>
            <div className='slider col-12'>
                {contents.map((content, index) => (
                    <div
                        className={index === current ? 'slide active' : 'slide'}
                        key={index}
                    >
                        {index === current && (
                            <div className='content'>
                                {content} {/* Render the content */}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Sliders