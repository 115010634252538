import React from 'react'
import NavBar from './components/NavBar'
import Successes from './assets/images/success.png'
import Good from './assets/images/good.png'
import Sterling from './assets/images/sterling.png'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';

function SuccessScreen() {
    return (
        <div style={{ width: '100%', backgroundColor: '#21416D', height: 1500}}>
            <NavBar />
            <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: 60}}>
               <img src={Successes} style={{width: 600}} />
            </div>
            <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: -50}}>
               <img src={Good} style={{width: 50}} />
            </div>
            <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: 5, color: '#fff'}}>
               Amazing
            </div>
            <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: 5, fontSize: 40, fontFamily: 'RobotoBold', textAlign: 'center', color: '#63BD43'}}>
               Congratulations you just got registerd
            </div>
            <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: 25}}>
               <div style={{width: 600, border: '1px solid #eee'}}></div>
            </div>
            <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: 15, fontSize: 20, fontFamily: 'RobotoBold', textAlign: 'center', color: '#FA0468', }}>
                <div style={{width: '80%', fontSize: 20, fontFamily: 'RobotoBold', textAlign: 'center', color: '#FA0468', }}> Kindly pay to the account number below and submit prove of payment to the phone number or email below</div>
            </div>
            <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: 15}}>
               <img src={Sterling} style={{width: 150}} />
            </div>
            <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: 5, fontSize: 40, fontFamily: 'RobotoBold', textAlign: 'center', color: '#fff'}}>
               ACCOUNT NUMBER: 0009539638
            </div>
            <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: 5, fontSize: 30, fontFamily: 'RobotoBold', textAlign: 'center', alignItems: 'center'}}>
                <div><WhatsAppIcon style={{color: '#fff', fontSize: 40}} /></div>
                 <div style={{marginLeft: 10, color: '#fff'}}>08155145432</div>
            </div>
            <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: 5, fontSize: 30, fontFamily: 'RobotoBold', textAlign: 'center', alignItems: 'center'}}>
                <div><EmailIcon style={{color: '#fff', fontSize: 40}} /></div>
                 <div style={{marginLeft: 10, color: '#fff'}}> gracevillexposure@gmail.com</div>
            </div>
        </div>
    )
}

export default SuccessScreen