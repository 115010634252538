import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './index.css'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import NavBar from './components/NavBar';
import HomeScreen from './screen/HomeScreen';
import TicketSales from './components/TicketSales';
import RegisterScreen from './screen/RegisterScreen';
import SuccessScreen from './SuccessScreen';
import ProofOfPayment from './screen/ProofOfPayment';

function App() {
  return (
    <div style={{width: '100%'}}>
      <Routes>
        <Route path='/' element={<HomeScreen />} />
        <Route path='/ticket' element={<TicketSales />} />
        <Route path='/register' element={<RegisterScreen />} />
        <Route path='/success' element={<SuccessScreen />} />
        <Route path='/proof' element={<ProofOfPayment />} />
      </Routes>
    </div>
  );
}

export default App;
