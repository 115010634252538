import React, { useState, useEffect } from 'react'
import NavBar from '../components/NavBar'
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import PersonIcon from '@mui/icons-material/Person';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { db } from '../firebase';

const customTheme = (outerTheme) =>
    createTheme({
        palette: {
            mode: outerTheme.palette.mode,
        },
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        '--TextField-brandBorderColor': '#E0E3E7',
                        '--TextField-brandBorderHoverColor': '#B2BAC2',
                        '--TextField-brandBorderFocusedColor': '#6F7E8C',
                        '& label.Mui-focused': {
                            color: 'var(--TextField-brandBorderFocusedColor)',
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        borderColor: 'var(--TextField-brandBorderColor)',
                    },
                    root: {
                        [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: 'var(--TextField-brandBorderHoverColor)',
                        },
                        [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: 'var(--TextField-brandBorderFocusedColor)',
                        },
                    },
                },
            },
            MuiFilledInput: {
                styleOverrides: {
                    root: {
                        '&::before, &::after': {
                            borderBottom: '2px solid var(--TextField-brandBorderColor)',
                        },
                        '&:hover:not(.Mui-disabled, .Mui-error):before': {
                            borderBottom: '2px solid var(--TextField-brandBorderHoverColor)',
                        },
                        '&.Mui-focused:after': {
                            borderBottom: '2px solid var(--TextField-brandBorderFocusedColor)',
                        },
                    },
                },
            },
            MuiInput: {
                styleOverrides: {
                    root: {
                        '&::before': {
                            borderBottom: '2px solid var(--TextField-brandBorderColor)',
                        },
                        '&:hover:not(.Mui-disabled, .Mui-error):before': {
                            borderBottom: '2px solid var(--TextField-brandBorderHoverColor)',
                        },
                        '&.Mui-focused:after': {
                            borderBottom: '2px solid var(--TextField-brandBorderFocusedColor)',
                        },
                    },
                },
            },
        },
    });

function RegisterScreen() {

    const outerTheme = useTheme();
    const navigation = useNavigate()

    const userData = [
        { firstName: 'John', lastName: 'Doe', email: 'john.doe@example.com', phoneNumber: '123-456-7890' },
        { firstName: 'Alice', lastName: 'Smith', email: 'alice.smith@example.com', phoneNumber: '987-654-3210' },
        { firstName: 'Bob', lastName: 'Johnson', email: 'bob.johnson@example.com', phoneNumber: '555-123-4567' },
    ];

    const [loading, setLoading] = useState(false)

    const [users, setUsers] = useState([])

    const [firstName, setFirstName] = useState('')
    const [middleName, setMiddleName] = useState('')
    const [surname, setSurname] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')

    const [firstNameError, setFirstNameError] = useState('')
    const [middleNameError, setMiddleNameError] = useState('')
    const [surnameError, setSurnameError] = useState('')
    const [emailError, setEmailError] = useState('')
    const [phoneError, setPhoneError] = useState('')

    useEffect(() => {
        db.collection('Users')
            .get()
            .then((snapshot) => {
                const user = snapshot.docs.map(doc => {
                    const data = doc.data()
                    const id = doc.id
                    return { data, id }
                })
                setUsers(user)
            })
            
    }, [])
    console.log('Users', users)
    const handleFirstName = (e) => {
        setFirstName(e.target.value)
    }

    const handleMiddleName = (e) => {
        setMiddleName(e.target.value)
    }

    const handleSurname = (e) => {
        setSurname(e.target.value)
    }

    const handleEmail = (e) => {
        setEmail(e.target.value)
    }

    const handlePhone = (e) => {
        setPhone(e.target.value)
    }

    const register = () => {
        // Check if the input matches any email or phone number in the data
        const matchEmail = users.find(
            (user) => user.data.Email === email
        );

        const matchPhone = users.find(
            (user) => user.data.PhoneNumber === phone
        );

        if (matchEmail) {
            setEmailError(`Email already exist`);
            console.log('email match')
        } else {
            console.log('Email didnt match', matchEmail)
        }

        if (matchPhone) {
            setPhoneError(`Number already exist`);
            console.log('phone match')
        } else {
            console.log('phone didnt match', matchPhone)
        }

        if (firstName == '') {
            setFirstNameError('Cannot be an empty field')
        }

        if (surname == '') {
            setSurnameError('Cannot be an empty field')
        }

        if (email == '') {
            setEmailError('Cannot be an empty string')
        }

        if (phone == '') {
            setPhoneError('Cannot be an empty string')
        }

        if (firstName !== '' && surname !== '' && email !== '' && !matchEmail && phone !== '' && !matchPhone) {
            console.log('Registered')
            setFirstNameError('')
            setSurnameError('')
            setEmailError('')
            setPhoneError('')
            setLoading(true)
            db.collection('Users')
                .add({
                    FirstName: firstName,
                    MiddleName: middleName,
                    Surname: surname,
                    PhoneNumber: phone,
                    Email: email,
                }).then(() => {
                    navigation('/success')
                    setLoading(false)
                }).catch(e => {
                    console.log(e)
                })
        }
    };

    return (
        <div style={{ width: '100%', height: 1000, backgroundColor: '#21416D' }}>
            <NavBar />
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 50 }}>
                <div style={{ width: 350, height: 'auto', backgroundColor: '#0D1222', border: '2px solid #2B89DF', borderRadius: 20, padding: 30 }}>
                    <div style={{ marginLeft: 20, marginTop: 40 }}>
                        <p style={{ color: '#2B89DF' }}>Let's get started</p>
                        <p style={{ fontSize: 23, color: '#fff', fontFamily: 'RobotoRegular' }}>Fill in your personal details</p>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <ThemeProvider theme={customTheme(outerTheme)}>
                            <TextField
                                label="First Name"
                                onChange={handleFirstName}
                                value={firstName}
                                focused
                                style={{ color: '#fff' }}
                                sx={{ m: 1, width: '28ch', color: '#fff' }}
                                InputLabelProps={{ style: { color: '#fff' } }} // Set the label text color
                                InputProps={{
                                    style: { color: '#fff' }, // Set the input text color
                                    startAdornment: <InputAdornment position="start"><PersonIcon color='#fff' style={{ fontSize: 20, color: '#fff' }} /></InputAdornment>,
                                }}
                            />
                            <div style={{ color: 'red', fontSize: 13 }}>{firstNameError}</div>
                        </ThemeProvider>
                        <ThemeProvider theme={customTheme(outerTheme)}>
                            <TextField
                                label="Middle Name"
                                onChange={handleMiddleName}
                                value={middleName}
                                focused
                                style={{ color: '#fff' }}
                                sx={{ m: 1, width: '28ch', color: '#fff' }}
                                InputLabelProps={{ style: { color: '#fff' } }} // Set the label text color
                                InputProps={{
                                    style: { color: '#fff' }, // Set the input text color
                                    startAdornment: <InputAdornment position="start"><PersonIcon color='#fff' style={{ fontSize: 20, color: '#fff' }} /></InputAdornment>,
                                }}
                            />
                        </ThemeProvider>
                        <ThemeProvider theme={customTheme(outerTheme)}>
                            <TextField
                                label="Surname"
                                onChange={handleSurname}
                                value={surname}
                                focused
                                style={{ color: '#fff' }}
                                sx={{ m: 1, width: '28ch', color: '#fff' }}
                                InputLabelProps={{ style: { color: '#fff' } }} // Set the label text color
                                InputProps={{
                                    style: { color: '#fff' }, // Set the input text color
                                    startAdornment: <InputAdornment position="start"><PersonIcon color='#fff' style={{ fontSize: 20, color: '#fff' }} /></InputAdornment>,
                                }}
                            />
                            <div style={{ color: 'red', fontSize: 13 }}>{surnameError}</div>
                        </ThemeProvider>
                        <ThemeProvider theme={customTheme(outerTheme)}>
                            <TextField
                                label="Email"
                                onChange={handleEmail}
                                value={email}
                                type='email'
                                focused
                                style={{ color: '#fff' }}
                                sx={{ m: 1, width: '28ch', color: '#fff' }}
                                InputLabelProps={{ style: { color: '#fff' } }} // Set the label text color
                                InputProps={{
                                    style: { color: '#fff' }, // Set the input text color
                                    startAdornment: <InputAdornment position="start"><AlternateEmailIcon color='#fff' style={{ fontSize: 20, color: '#fff' }} /></InputAdornment>,
                                }}
                            />
                            <div style={{ color: 'red', fontSize: 13 }}>{emailError}</div>
                        </ThemeProvider>
                        <ThemeProvider theme={customTheme(outerTheme)}>
                            <TextField
                                label="Phone Number"
                                onChange={handlePhone}
                                value={phone}
                                focused
                                style={{ color: '#fff' }}
                                sx={{ m: 1, width: '28ch', color: '#fff' }}
                                InputLabelProps={{ style: { color: '#fff' } }} // Set the label text color
                                InputProps={{
                                    style: { color: '#fff' }, // Set the input text color
                                    startAdornment: <InputAdornment position="start"><PhoneInTalkIcon color='#fff' style={{ fontSize: 20, color: '#fff' }} /></InputAdornment>,
                                }}
                            />
                            <div style={{ color: 'red', fontSize: 13 }}>{phoneError}</div>
                        </ThemeProvider>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ width: 280, height: 45, borderRadius: 5, marginTop: 10, color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 15, cursor: 'pointer', backgroundColor: '#2B89DF' }} onClick={register}>
                            {loading == false ? (
                                <>REGISTER</>
                            ): (
                                <CircularProgress size={20} style={{color: '#fff'}} />
                            )}
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RegisterScreen