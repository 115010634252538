import React from 'react'
import Banner from '../assets/images/banner2.jpeg'
import NavBar from './NavBar'
import '../index.css'
import { useNavigate } from 'react-router-dom'

function TicketSales() {

    const navigation = useNavigate()

    return (
        <div style={{ width: '100%', position: 'relative' }}>
            <div className="image-container1">
                <img src={Banner} alt="Background" className="background-image1" />
                <div className="overlay1">
                    <NavBar />
                    <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 30}}>
                            <p style={{color: '#A13960'}}>PRICING TABLE</p>
                            <p style={{fontSize: 45, fontFamily: 'RobotoBold', color: '#fff', textAlign: 'center'}}>GET YOUR TICKET !!</p>
                        </div>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',}}>
                        <div style={{ width: 350, height: 500, backgroundColor: '#fff', marginTop: 50, borderTopLeftRadius: 25, borderTopRightRadius: 25, position: 'relative' }}>
                            <div style={{ width: '100%', height: 55, backgroundColor: '#A13960', borderTopLeftRadius: 25, borderTopRightRadius: 25, display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#fff' }}>
                                EXCLUSIVE OFFER!
                            </div>
                            <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 50}}>
                                <p style={{color: '#A13960'}}>PRICE</p>
                                <p style={{fontSize: 50, fontFamily: 'RobotoBold', marginTop: -10}}>₦10,000</p>
                                <p style={{color: '#696A87', fontSize: 16, width: '90%', textAlign: 'center'}}>Tickets for parenting with grace now on sale. Get your's now!!</p>
                                <p style={{color: '#696A87', fontSize: 16, width: '90%', textAlign: 'center', fontFamily: 'RobotoBold',}}>Online streaming only</p>
                            </div>
                            <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20}}>
                            <div style={{ width: 200, height: 35, borderRadius: 35, border: '1px solid #A13960', marginTop: 10, color: '#A13960', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 15, cursor: 'pointer', }} onClick={() => navigation('/register')}>
                                BUY YOUR TICKET
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TicketSales