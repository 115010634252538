import React, { useState, useEffect } from 'react';
import Banners from '../assets/images/banner.jpeg'
import NavBar from './NavBar'
import Funke from '../assets/images/funke1.png'
import '../index.css'
import { useNavigate } from 'react-router-dom';

function Banner() {

    const navigation = useNavigate()

    const [screenSize, setScreenSize] = useState(getCurrentDimension());

    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);


        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize])

    const calculateTimeLeft = () => {
        const difference = +new Date('2024-02-10') - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        // Clear timeout if the component is unmounted
        return () => clearTimeout(timer);
    });

    return (
        <div className='image-container'>
            <img src={Banners} alt="Background" className="background-image" style={{ zIndex: 0 }} />
            <div className="overlay" style={{ zIndex: 200 }}>
                <NavBar />
                {screenSize.width < 800 ? (
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around', position: 'absolute', flexDirection: 'column', alignItems: 'center' }}>
                        <div style={{ marginRight: 0, marginTop: 50, display: 'flex', flexDirection: 'column', alignItems: 'center', zIndex: 200 }}>
                            <div style={{ color: '#fff', fontSize: 20, fontWeight: 'bold', marginBottom: -20, fontFamily: 'PTSans' }}>PARENTING WITH</div>
                            <div style={{ color: '#FA0468', fontSize: 80, fontWeight: 'bold', fontFamily: 'SalsaRegular' }}>GRACE</div>
                            <div style={{ width: 100, border: '2px solid #fff', marginTop: 0, }}></div>
                            <div style={{ color: '#fff', fontSize: 20, fontWeight: 'bold', marginTop: 10, fontFamily: 'PTSans' }}>10TH FEBRUARY 2024</div>
                            <div style={{ color: '#fff', fontSize: 20, fontWeight: 'bold', marginTop: 10, fontFamily: 'PTSans' }}>9:00AM</div>
                            <div style={{ color: '#fff' }}>With Funke Felix-Adejumo</div>
                            <div style={{ color: '#fff' }}>Guest minister</div>
                            <div style={{ color: '#fff', marginTop: 10 }}>Powered by Graceville Christian Centre</div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: 180, height: 35, borderRadius: 35, border: '1px solid #fff', marginTop: 10, color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 15, cursor: 'pointer', zIndex: 200, marginRight: 5 }} className="color-change-button" onClick={() => navigation('/ticket')}>
                                    BUY YOUR TICKET
                                </div>
                                <div style={{ width: 180, height: 35, borderRadius: 35, border: '1px solid #fff', marginTop: 10, color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 15, cursor: 'pointer', zIndex: 200, marginLeft: 5 }} className="color-change-button" onClick={() => navigation('/proof')}>
                                    PROOF OF PAYMENT
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: 70 }}>
                            <img src={Funke} style={{ width: 350, height: 350 }} />
                        </div>
                    </div>
                ) : (
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around', position: 'absolute' }}>
                        <div style={{ marginTop: 100 }}>
                            <img src={Funke} style={{ width: 650, height: 650 }} />
                        </div>
                        <div style={{ marginRight: 300, marginTop: 140, display: 'flex', flexDirection: 'column', alignItems: 'center', zIndex: 200 }}>
                            <div style={{ color: '#fff', fontSize: 50, fontWeight: 'bold', marginBottom: -40, fontFamily: 'PTSans' }}>PARENTING WITH</div>
                            <div style={{ color: '#FA0468', fontSize: 150, fontWeight: 'bold', fontFamily: 'SalsaRegular' }}>GRACE</div>
                            <div style={{ width: 150, border: '2px solid #fff', marginTop: -20, }}></div>
                            <div style={{ color: '#fff', fontSize: 50, fontWeight: 'bold', marginTop: 0, fontFamily: 'PTSans' }}>10TH FEBRUARY 2024</div>
                            <div style={{ color: '#fff', fontSize: 30, fontWeight: 'bold', marginTop: 10, fontFamily: 'PTSans' }}>9:00AM</div>
                            <div style={{ color: '#fff' }}>With Funke Felix-Adejumo</div>
                            <div style={{ color: '#FFCC2A', fontSize: 13 }}>Guest minister</div>
                            <div style={{ color: '#fff', marginTop: 10 }}>Powered by Graceville Christian Centre</div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: 200, height: 35, borderRadius: 35, border: '1px solid #fff', marginTop: 10, color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 15, cursor: 'pointer', zIndex: 200, marginRight: 5 }} className="color-change-button" onClick={() => navigation('/ticket')}>
                                    BUY YOUR TICKET
                                </div>
                                <div style={{ width: 200, height: 35, borderRadius: 35, border: '1px solid #fff', marginTop: 10, color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 15, cursor: 'pointer', zIndex: 200, marginLeft: 5 }} className="color-change-button" onClick={() => navigation('/proof')}>
                                    PROOF OF PAYMENT
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Banner