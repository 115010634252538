import React, { useState, useEffect } from 'react'
import one from '../assets/images/1.jpeg'
import two from '../assets/images/2.jpeg'
import three from '../assets/images/3.jpeg'
import four from '../assets/images/4.jpeg'
import five from '../assets/images/5.jpeg'
import six from '../assets/images/6.jpeg'
import seven from '../assets/images/7.jpeg'
import eight from '../assets/images/8.jpeg'
import nine from '../assets/images/nine.jpeg'
import ten from '../assets/images/ten.jpeg'
import eleven from '../assets/images/eleven.jpeg'
import twelve from '../assets/images/twelve.jpeg'
import thirteen from '../assets/images/thirteen.jpeg'
import fourteen from '../assets/images/fourteen.jpeg'
import fifteen from '../assets/images/fifteen.jpeg'
import sixteen from '../assets/images/sixteen.jpeg'

import Box from '@mui/material/Box';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

function Event() {

    const image = [
        { id: 15, img: sixteen },
        { id: 14, img: fifteen },
        { id: 13, img: fourteen },
        { id: 12, img: thirteen },
        { id: 11, img: twelve },
        { id: 2, img: two },
        { id: 10, img: eleven },
        { id: 9, img: ten },
        { id: 7, img: seven },
        { id: 5, img: five },
        { id: 8, img: eight },
        { id: 3, img: three },
        { id: 4, img: four },
        { id: 6, img: six },
        { id: 1, img: one },
    ]

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [screenSize, setScreenSize] = useState(getCurrentDimension());

    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);


        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize])

    return (
        <div style={{ width: '100%', height: 'auto', backgroundColor: '#fff', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header>
                <Modal.Body>Woohoo, you are reading this text in a modal!</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
            <div style={{ width: '80%', marginTop: 150 }}>
                <div style={{ display: 'flex' }}>
                    <div>
                        <div style={{ color: '#FA0468' }}>EVENT GALLERY</div>
                        <div style={{ width: 320, fontSize: 30, fontFamily: 'RobotoBold' }}>COLLECTION OF IMAGES FROM PREVIOUS YEARS!!</div>
                    </div>
                    <div></div>
                </div>

                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', gap: 10, paddingBottom: 40, marginTop: 20 }}>
                    {image.map((item, index) => (
                        <div key={index} style={{ width: 300, height: 300, boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)', borderRadius: 20, overflow: 'hidden' }}>
                            <img src={item.img} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Event