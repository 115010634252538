import React, {useState, useEffect} from 'react'
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import Facebook from '../assets/images/facebook.png'
import Twitter from '../assets/images/twitter.png'
import Youtube from '../assets/images/youtube.png'
import Instagram from '../assets/images/instagram.png'

function NavBar() {

    const [screenSize, setScreenSize] = useState(getCurrentDimension());

    function getCurrentDimension() {
        return {
          width: window.innerWidth,
          height: window.innerHeight
        }
      }
    
      useEffect(() => {
        const updateDimension = () => {
          setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);
    
    
        return (() => {
          window.removeEventListener('resize', updateDimension);
        })
      }, [screenSize])

    return (
        <div style={{ width: '100%', height: 70, border: '1px solid #fff', borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, display: 'flex', justifyContent: screenSize.width < 500 ? 'center' : 'space-around', zIndex: 100, alignItems: 'center' }}>
            {screenSize.width < 500 ? (
                <></>
            ): (
                <div style={{display: 'flex'}}>
               <div style={{display: 'flex', alignItems: 'center'}}>
                  <div style={{width: 40, height: 40, borderRadius: 60, backgroundColor: '#FA0468', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <PhoneInTalkIcon color='#fff' style={{fontSize: 20, color: '#fff'}} />
                  </div>
                  <div style={{marginLeft: 15}}>
                    <div style={{fontSize: 14, color: '#F5F5F5'}}>PHONE NUMBER:</div>
                    <div style={{fontSize: 14, color: '#F5F5F5'}}>08155145432</div>
                  </div>
               </div>
               <div style={{display: 'flex', alignItems: 'center', marginLeft: 40}}>
                  <div style={{width: 40, height: 40, borderRadius: 60, backgroundColor: '#FA0468', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <AlternateEmailIcon color='#fff' style={{fontSize: 20, color: '#fff'}} />
                  </div>
                  <div style={{marginLeft: 15}}>
                    <div style={{fontSize: 14, color: '#F5F5F5'}}>EMAIL ADDRESS:</div>
                    <div style={{fontSize: 14, color: '#F5F5F5'}}>gracevillexposure@gmail.com</div>
                  </div>
               </div>
            </div>
            )}
            
            <div style={{display: 'flex'}}>
                <div style={{width: 35, height: 35, border: '1px solid #ccc', borderRadius: 40, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                   <img src={Facebook} style={{width: 14, height: 14}} />
                </div>
                <div style={{width: 35, height: 35, border: '1px solid #ccc', borderRadius: 40, display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: 5}}>
                   <img src={Twitter} style={{width: 14, height: 14}} />
                </div>
                <div style={{width: 35, height: 35, border: '1px solid #ccc', borderRadius: 40, display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: 5}}>
                   <img src={Youtube} style={{width: 14, height: 14}} />
                </div>
                <div style={{width: 35, height: 35, border: '1px solid #ccc', borderRadius: 40, display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: 5}}>
                   <img src={Instagram} style={{width: 14, height: 14}} />
                </div>
            </div>
        </div>
    )
}

export default NavBar